import {Avatar, Skeleton} from "@mantine/core";
import {useEffect, useState} from "react";

export default function BigAvatar({src}) {
    const[loaded, setLoaded] = useState(true);

    useEffect(() => {
        if(src != null) {
            setLoaded(false);
        }
        else {
            setLoaded(true);
        }
    }, [src])

    return(
        <>

        <Skeleton visible={!loaded} height={80} width={80} radius="xl">
            <Avatar radius="xl" size={80} onLoad={() => {setLoaded(true)}} src={src} color="accent" />
        </Skeleton>
        </>
    )
}