import "./Masonry.css";
import Masonry from "react-masonry-css";


export function MasonryGrid({children}) {
    const breakpointColumnsObj = {
        default: children.length < 3 ? children.length : 3,
        1100: children.length < 2 ? children.length : 2,
        700: 1
    };
    return(
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {children}
        </Masonry>
    );
}
