import { Button, ButtonGroup, Content, Dialog, Divider, Flex, Footer, Header, Heading, Image, Link, Text, useDialogContainer } from '@adobe/react-spectrum';
import hero from'../../assets/img/MacOSApp.png';
import Cookies from "js-cookie";



export function MacOSEngagement() {

    let dialog = useDialogContainer();
  return (
    <Dialog size="L">

            <Image
                slot="hero"
                alt=""
                height="100%"
                src={hero}
                objectFit="cover"

            />
        <ButtonGroup>
            <Button variant="secondary" onPress={() => {dialog.dismiss(); Cookies.set('macOSEngagement', 'true', { expires: 7 })}}>Ask me later</Button>
        <Button autoFocus type='submit' variant="accent" onPress={() => {dialog.dismiss(); Cookies.set('macOSEngagement', 'true', { expires: 180 }); window.open("https://apps.apple.com/us/app/ysendit/id6443577070?mt=12&itsct=apps_box_link&itscg=30200", "_self")}}>Install</Button>
      </ButtonGroup>
      <Content>
      <Flex direction="column" gap="size-125">
        <Text>
        Download our app for macOS and share files hassle-free from the menubar or any other app like Finder and Photos using the Share Extension.
        </Text>
<Text>
Access your made transfers and sync them with the web version using your ysendit.com account
</Text>
</Flex>
      </Content>

      <Footer isHidden={{ base: true, S: false }}>
      <Link>
      <a href="https://help.ysendit.com/apps/macos-app" target="_blank">
        Want to learn more?
        </a>
        </Link>
      </Footer>

    </Dialog>

  );
}
