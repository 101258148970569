
import {createStyles, Paper, Text, Title, Button, rem, Image, Stack} from '@mantine/core';

import image from '../../assets/img/gradient-bg.jpeg';

const useStyles = createStyles((theme) => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},

	title: {
		fontFamily: `Greycliff CF ${theme.fontFamily}`,
		fontWeight: 800,
		lineHeight: 1.2,
		fontSize: rem(30),
		marginTop: theme.spacing.xl,
	},

	description: {
		opacity: 0.7,
		fontWeight: 700,
		marginTop: theme.spacing.sm,
	},
	cta: {
		marginTop: theme.spacing.xl,
	},
}));

export function Future2({ title, description, cta, ctaLink, img, noBackground=false }) {
	const { classes } = useStyles();

	return (
		<Paper
			shadow="md"
			p="xl"
			radius="md"
			sx={(noBackground) ? {} : { backgroundImage: `url(${image})` }}
			className={classes.card}
		>
			<div>
				<Image width="52px" fit="contain" src={img} />
				<Title order={3} className={classes.title}>
					{title}
				</Title>
				<Text className={classes.description} size="md">
					{description}
				</Text>
			</div>
			<Button component="a" href={ctaLink} variant="outline" className={classes.cta} color="dark">
				{cta}
			</Button>
		</Paper>);
}