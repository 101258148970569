import {useContext, useEffect, useRef, useState} from 'react';
import {
	createStyles,
	Header,
	Container,
	Group,
	Burger,
	Paper,
	Button,
	Transition,
	rem, Skeleton, useMantineColorScheme, Center,
} from '@mantine/core';
import {useColorScheme, useDisclosure} from '@mantine/hooks';

import {websiteContext} from "../data/websiteContext";

import "./Masonry/Masonry.css"
import {DarkModeSwitch} from "react-toggle-dark-mode";
import {colorschemeContext} from "../data/colorschemeContext";

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
	root: {
		position: 'sticky',
		top: "15px",
		zIndex: 1,
		borderRadius: theme.radius.md,
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		marginBottom: 120,

		[theme.fn.smallerThan('xs')]: {
			marginBottom: 40
		},
	},


	dropdown: {
		position: 'absolute',
		top: HEADER_HEIGHT,
		left: 0,
		right: 0,
		zIndex: 0,
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
		borderTopWidth: 0,
		overflow: 'hidden',

		[theme.fn.largerThan('sm')]: {
			display: 'none',
		},
	},

	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%'
	},

	links: {
		[theme.fn.smallerThan('sm')]: {
			display: 'none',
		},
	},

	burger: {
		[theme.fn.largerThan('sm')]: {
			display: 'none',
		},
	},

	link: {
		display: 'block',
		lineHeight: 1,
		padding: `${rem(8)} ${rem(12)}`,
		borderRadius: theme.radius.sm,
		textDecoration: 'none',
		color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
		fontSize: theme.fontSizes.sm,
		fontWeight: 500,

		'&:hover': {
			backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
		},

		[theme.fn.smallerThan('sm')]: {
			borderRadius: 0,
			padding: theme.spacing.md,
		},
	},

	linkActive: {
		'&, &:hover': {
			backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
			color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
		},
	},
}));


export function WebsiteHeader({ links, openUpgrade, active }) {
	const [opened, { toggle, close }] = useDisclosure(false);
	const { classes, cx } = useStyles();

	const siteData = useContext(websiteContext).siteData;



	const items = links.map((link) => (
		<a
			key={link.label}
			href={link.link}
			onClick={() => {
				if(link.upgrade) {
					openUpgrade(true);
				}
			}}
			className={cx(classes.link, { [classes.linkActive]: active === link.link })}
		>
			{link.label}
		</a>
	));

	const scheme = useContext(colorschemeContext);


	return (
		<Header height={HEADER_HEIGHT} className={classes.root}>
			<Container className={classes.header}>
				{(siteData == null) &&
				<Skeleton height={38} width={38} radius="sm" />
				}
				<img height="38px" src={(siteData == null) ? null : siteData.logo}/>
				<Group spacing={5} className={classes.links}>
					{items}

				</Group>

				<Group>
					<DarkModeSwitch
						checked={!scheme.isDark}
						onChange={(value) => {scheme.toggle(!value)}}
						size={25}
						moonColor="#5017c2"
						sunColor="#f5c842"
					/>

					<Burger opened={opened} className={classes.burger} onClick={toggle} size="sm" />
				</Group>


				<Transition transition="pop-top-right" duration={200} mounted={opened}>
					{(styles) => (
						<Paper className={classes.dropdown} withBorder style={styles}>
							{items}
						</Paper>
					)}
				</Transition>
			</Container>
		</Header>
	);
}