import {defaultTheme, Provider} from "@adobe/react-spectrum";
import App from "./App";
import React, {useEffect, useState} from "react";
import {colorschemeContext} from "./Components/data/colorschemeContext";
import {useToggle} from "@mantine/hooks";

export function Wrapper() {
    const [isDark, toggle] = useState(false)
    const value = { isDark, toggle };

    useEffect(() => {
        // Add listener to update styles
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => toggle(e.matches));

        // Setup dark/light mode for the first time
        toggle(window.matchMedia('(prefers-color-scheme: dark)').matches)

        // Remove listener
        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
            });
        }
    }, []);

    return(
        <colorschemeContext.Provider value={value}>
        <Provider theme={defaultTheme} colorScheme={isDark ? "dark" : "light"}>
            <App />
        </Provider>
        </colorschemeContext.Provider>
    )
}